/* eslint-disable no-unused-vars */
import React from "react";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import "./Nvep.css";
import { Navbar, Nav, NavDropdown } from "react-bootstrap";
import image1 from "../resources/Group 815.png";
import image2 from "../resources/Vector (1).svg";
import image3 from "../resources/star.svg";
import image4 from "../resources/Group.svg";
import banner1 from "../resources/1.png";
import banner2 from "../resources/2.png";
import banner3 from "../resources/3.png";
import founder1 from "../resources/IMG-20240131-WA0022.jpg";
import founder2 from "../resources/IMG-20240131-WA0068.jpg";
import gallery1 from "../resources/IMG-20240131-WA0012.jpg";
import gallery2 from "../resources/IMG-20240131-WA0015.jpg";
import gallery3 from "../resources/IMG-20240131-WA0039.jpg";
import gallery4 from "../Gallery/IMG-20240131-WA0003.jpg";
import gallery5 from "../Gallery/IMG-20240131-WA0043.jpg";
import gallery6 from "../Gallery/IMG-20240131-WA0051.jpg";
import logo from "../resources/logo.svg";
import phone from "../resources/call.svg";
import address from "../resources/location.svg";
import mail from "../resources/mail.svg";
import nv from "../resources/nv.svg";
import about1 from "../resources/About 2-1.jpg";
import about2 from "../resources/about 1-1.jpg";
import about3 from "../resources/About 3-1.jpg";
import nv2 from "../resources/LOGONVEP2.png";
import coupon from "../resources/IMG-20240311-WA0001.jpg";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";

const Nvep = () => {
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "561",
    //   border:"none"
    outline: "none",
  };

  const [open, setOpen] = React.useState(true);
  // const handleOpen = () => setOpen(false);
  const handleClose = () => {
    setOpen(false);
  };

  const [showMore, setShowMore] = useState(false);

  const toggleShowMore = () => {
    setShowMore(!showMore);
  };

  const scrollToSection = (sectionId) => {
    const section = document.getElementById(sectionId);
    if (section) {
      section.scrollIntoView({ behavior: "smooth" });
    }
  };

  const [fname, setfname] = useState("");
  const [number, setNumber] = useState("");
  const [email, setEmail] = useState("");
  const [discription, setdiscription] = useState("");
  const [dataSubmitted, setdataSubmitted] = useState("");
  const navigate = useNavigate();
  const [showContent, setShowContent] = useState(false);

  const toggleContent = () => {
    setShowContent(!showContent);
  };

  const submitdata = (e) => {
    e.preventDefault();
    console.log(fname, number, discription, email, "testing");
    const data = {
      fname: fname,
      number: number,
      email: email,
      discription: discription,
    };

    mailsend(data);
  };

  const mailsend = (data) => {
    console.log(data);
    const url = "https://vinayak-institution.onrender.com/sendmail";
    try {
      fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      }).then((dataa) => {
        if (dataa.ok === true) {
          setdataSubmitted(true);
          setfname("");
          setEmail("");
          setNumber("");
          setdiscription("");
        }
      });
    } catch {}
  };

  return (
    <>
      <Modal open={open} onClose={handleClose}>
        <Box sx={style}>
          <img src={coupon} alt="" style={{ height: "35vw" }} />
        </Box>
      </Modal>

      <div className="container-fluid">
        <div className="row back">
          <div className="col-md-3 d-none d-sm-block ">
            <div className=" w-75 m-auto">
              {/* <img
                src={phone}
                className=""
                style={{ height: "1.5vw" }}
                alt=""
              /> */}
              <span className="info" style={{ color: "white" }}>
                +919461478474,+919461028798
              </span>
            </div>
          </div>

          <div className="col-md-4  d-none d-sm-block">
            <div className="w-50 m-auto">
              <span className="info" style={{ color: "white" }}>
                education20point@gmail.com
              </span>
            </div>
          </div>

          <div className="col-md-4  d-none d-sm-block ">
            <div className=" m-auto">
              <span className="  info" style={{ color: "white" }}>
                <span className="fw-bold">New Vinayak Education Point</span>
                ,C-13 Above Bikaner Misthan Bhandar 2nd Floor , Vaishali Nagar
                Ajmer
              </span>
            </div>
          </div>
        </div>
      </div>

      <div className="container-fluid">
        {/* <!navbar---------------------------------> */}
        <div className="row mt-5  mx-3">
          {/* <div className='col-sm-4 '>
                    <img src={logo} className='logo' style={{height:"3vw"}} alt="" />
                </div> */}

          {/* <div className='col-sm-8 mb-5'>
                        <ul class="nav justify-content-end fs-5">
                <li class="nav-item">
                    <a class="nav-link active" aria-current="page" href="#cou">Courses</a>
                </li>
                <li class="nav-item">
                    <a class="nav-link" onClick={()=>{(navigate("/Gallerysection"))}} href="#">Gallery</a>
                </li>
                <li class="nav-item">
                    <a class="nav-link" href="#abou">About Us</a>
                </li>
                <li class="nav-item">
                    <a class="nav-link" href="#con">Contact us</a>
                </li>
                </ul>
                </div> */}
          {/* <div className='col-sm-12 '> */}
          <Navbar
            collapseOnSelect
            expand="lg"
            variant="light"
            className="fs-4 col-sm-12"
          >
            <Navbar.Brand
              className="ms-4 "
              href=""
              onClick={() => scrollToSection("home")}
            >
              <img
                src={logo}
                style={{ height: "8vw", cursor: "pointer" }}
                alt=""
                className="mb-3"
              />
              <span className="fs-3" style={{ color: "#002D68" }}>
                Focus On Education
              </span>
            </Navbar.Brand>
            {/* <Navbar.Brand className='ms-4 d-md-none d-lg-none d-xl-none d-xxl-none' href="#" ><img src={logo} style={{height:"10vw"}} alt="" /></Navbar.Brand> */}
            <Navbar.Toggle aria-controls="responsive-navbar-nav" />
            <Navbar.Collapse id="responsive-navbar-nav ">
              <Nav className="ms-auto  ">
                <NavDropdown
                  href=""
                  className="me-5"
                  title="Courses"
                  // onClick={() => scrollToSection("cou")}
                  id="collasible-nav-dropdown"
                >
                  <NavDropdown.Item href="">RS-CIT</NavDropdown.Item>
                  <NavDropdown.Item href="">Tally</NavDropdown.Item>
                  <NavDropdown.Item href="">Advance Excel</NavDropdown.Item>
                  <NavDropdown.Item href="">English Spoken</NavDropdown.Item>
                  <NavDropdown.Item href="">DCOC</NavDropdown.Item>
                  <NavDropdown.Item href="">DCA</NavDropdown.Item>
                  <NavDropdown.Item href="">ADCTT</NavDropdown.Item>
                  <NavDropdown.Item href="">Computer Basic</NavDropdown.Item>
                  <NavDropdown.Item href="">BCA</NavDropdown.Item>
                  <NavDropdown.Item href="">MCA</NavDropdown.Item>
                  <NavDropdown.Item href="">PGDCA</NavDropdown.Item>
                </NavDropdown>
                <Nav.Link
                  href="#"
                  className="me-5"
                  onClick={() => {
                    navigate("/Gallerysection");
                  }}
                >
                  Gallery
                </Nav.Link>
                <Nav.Link
                  href=""
                  className="me-5"
                  onClick={() => scrollToSection("abou")}
                >
                  About Us
                </Nav.Link>
                <Nav.Link
                  href=""
                  className="me-5"
                  onClick={() => scrollToSection("con")}
                >
                  Contact Us
                </Nav.Link>
              </Nav>
            </Navbar.Collapse>
          </Navbar>
          {/* </div> */}
        </div>

        <div className="container-fluid">
          <div className="row mb-5 mt-5">
            <div
              id="carouselExampleControls"
              class="carousel slide"
              data-bs-ride="carousel"
            >
              <div class="carousel-inner">
                <div class="carousel-item active">
                  <img src={banner1} class="d-block w-100" alt="..." />
                </div>
                <div class="carousel-item">
                  <img src={banner2} class="d-block w-100 " alt="..." />
                </div>
                <div class="carousel-item">
                  <img src={banner3} class="d-block w-100" alt="..." />
                </div>
              </div>
              <button
                class="carousel-control-prev"
                type="button"
                data-bs-target="#carouselExampleControls"
                data-bs-slide="prev"
              >
                <span
                  class="carousel-control-prev-icon"
                  aria-hidden="true"
                ></span>
                <span class="visually-hidden">Previous</span>
              </button>
              <button
                class="carousel-control-next"
                type="button"
                data-bs-target="#carouselExampleControls"
                data-bs-slide="next"
              >
                <span
                  class="carousel-control-next-icon"
                  aria-hidden="true"
                ></span>
                <span class="visually-hidden">Next</span>
              </button>
            </div>
          </div>
        </div>
      </div>

      {/* section two */}
      <div className="container">
        <div className="section2  mb-5 p-5" style={{ minHeight: "100%" }}>
          <div className="row">
            <div className="col-sm-4 ">
              <div>
                <img
                  className="mt-5"
                  style={{ height: "81px" }}
                  src={image2}
                  alt=""
                ></img>
              </div>
              <div className="mt-4">
                <span className="fs-4">5000+ Students</span>
              </div>
            </div>
            <div className="col-sm-4 ">
              <div>
                <img className="mt-5" src={image3} alt=""></img>
              </div>
              <div className="mt-4">
                <span className="fs-4">5000+ Students</span>
              </div>
            </div>
            <div className="col-sm-4 mb-5">
              <div>
                <img className="mt-5" src={image4} alt=""></img>
              </div>
              <div className="mt-4 mb-5">
                <span className="fs-4">5000+ Students</span>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* section4 */}
      <div className="container">
        <div id="abou">
          <div className="row mt-5 py-5">
            <div className="col-sm-7">
              <h2 className="fs-1">About Us</h2>
              <p className="fs-4 head1 m-3">
                Welcome To NVEP [New Vinayak Education Point], Where Education
                Meets Excellence. NVEP Is More Than Just An Educational
                Institution; We Are A Community Of Passionate Educators,
                Dedicated Students, And Supportive Parents. Established On 03
                June 2004, We Have Been Consistently Delivering Outstanding
                Education And Shaping The Future Leaders Of Tomorrow.
              </p>
            </div>
            <div className="col-sm-5 ">
              <div
                id="carouselExampleInterval"
                class="carousel slide"
                data-bs-ride="carousel"
              >
                <div class="carousel-inner">
                  <div class="carousel-item active" data-bs-interval="10000">
                    <img src={about1} class="d-block" alt="..." />
                  </div>
                  <div class="carousel-item" data-bs-interval="2000">
                    <img src={about2} class="d-block " alt="..." />
                  </div>
                  <div class="carousel-item">
                    <img src={about3} class="d-block " alt="..." />
                  </div>
                </div>
                <button
                  class="carousel-control-prev"
                  type="button"
                  data-bs-target="#carouselExampleInterval"
                  data-bs-slide="prev"
                >
                  <span
                    class="carousel-control-prev-icon"
                    aria-hidden="true"
                  ></span>
                  <span class="visually-hidden">Previous</span>
                </button>
                <button
                  class="carousel-control-next"
                  type="button"
                  data-bs-target="#carouselExampleInterval"
                  data-bs-slide="next"
                >
                  <span
                    class="carousel-control-next-icon"
                    aria-hidden="true"
                  ></span>
                  <span class="visually-hidden">Next</span>
                </button>
              </div>
            </div>
          </div>
        </div>

        {/* section5 */}
        <div className="container-fluid">
          <div className="mb-5">
            <div className="row p-5 ">
              <div className="col-sm-12">
                <h2 className="fs-1">Founders</h2>
              </div>
            </div>
            <div className="container">
              {/* <div className="row  founder ">
                <div className=" col-sm-4 position-relative">
                  <div className="mt-5">
                    <h2>Meet our</h2>
                  </div>
                  <div className="">
                    <h2 className="fs-1">Director</h2>
                  </div>
                  <div className="d-none d-sm-block position-absolute bottom-0 start-0 ">
                    <img src={nv} alt="" />
                  </div>
                </div>

                <div className="col-sm-4 order-1 order-sm-0 order-md-0 order-lg-0 order-xl-0 order-xxl-0">
                  <div className="mt-5 py-5 order-md-1">
                    <h2>Shashikant Verma</h2>
                  </div>

                  <div
                    className="order-2 order-sm-2"
                    style={{ textAlign: "justify" }}
                  >
                    <span className="quotes fs-5">
                      “Be Educated, Be Agitated, Be Organized, Be Confident,
                      Never Give Up, These Are The Five Principles Of Our Life”
                    </span>
                  </div>
                </div>

                <div className="col-sm-4 mt-5 order-0 order-sm-2 order-md-2 order-lg-2 order-xl-2 order-xxl-2">
                  <div>
                    <img
                      src={founder1}
                      className=""
                      alt=""
                      style={{ width: "12vw" }}
                    />
                  </div>
                </div>
              </div> */}

              <div className="row  mt-5 ms-1">
                <div className="row founder ">
                  <div className=" col-12 col-sm-4 mt-5 order-2 order-sm-0 order-md-0 order-lg-0 order-xl-0 order-xxl-0">
                    <img
                      src={founder2}
                      className="mt-5"
                      alt=""
                      style={{ width: "12vw" }}
                    />
                  </div>

                  <div className="col-sm-4 order-2 order-sm-1 order-md-1 order-lg-1 order-xl-1 order-xxl-1">
                    <div className="mt-5 py-5">
                      <h2>Sunita Verma</h2>
                    </div>

                    <div className="" style={{ textAlign: "justify" }}>
                      <span className="quotes fs-5">
                        “Be Educated, Be Agitated, Be Organized, Be Confident,
                        Never Give Up, These Are The Five Principles Of Our
                        Life”
                      </span>
                    </div>
                  </div>

                  <div className="col-12 col-sm-4 order-1 order-sm-2  position-relative">
                    <div>
                      <div className="d-none d-sm-block position-absolute top-0 end-0 mx-5">
                        <img src={nv2} alt="" />
                      </div>
                      <div className=" mt-5  position-absolute top-50 end-0 translate-middle-y me-5 px-4">
                        <h2>Meet our</h2>
                        <h2 className="fs-1">Director</h2>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* section6 */}

      <div id="cou">
        <div className="col-sm-12  mt-5 py-5">
          <h5 className=" fs-1 head1 ms-5 mx-auto  text-center">Our Courses</h5>
        </div>
        <div className="container-fluid courses">
          <div className="row justify-content-center mx-3 mb-5">
            <div className="col-sm-2  core py-5 mx-3 border mt-5">
              <div>
                <h2 className="head">BCA</h2>
              </div>
              <div className="head">
                <span>
                  BCA is a three-year program focusing on computer applications,
                  providing skills in programming and software development for
                  careers in IT.
                </span>
              </div>
            </div>

            <div className="col-sm-2 core py-5 mx-3  border mt-5">
              <div className="head">
                <h2>ADCTT</h2>
              </div>
              <div className="head">
                <span>
                  ADCTT automates daclassNamepture, transfer, and
                  transformation, enhancing workflow efficiency across
                  industries.
                </span>
              </div>
            </div>

            <div className="col-sm-2 core py-5 mx-3 border mt-5">
              <div className="head">
                <h2>CTT</h2>
              </div>
              <div className="head">
                <span>
                  CTT (Capture, Transform, Transfer) automates data handling
                  processes, enhancing efficiency across industries.
                </span>
              </div>
            </div>

            <div className="col-sm-2 core py-5 mx-3 border mt-5">
              <div className="head">
                <h2>DCA</h2>
              </div>
              <div className="head">
                <span>
                  DCA: Short course covering computer applications, programming
                  basics, and software skills for entry-level IT roles.
                </span>
              </div>
            </div>
            {/* More course items here */}
          </div>
          <div className="row justify-content-center  mx-3 mb-5">
            <div className="col-sm-2 core border py-5 mx-3  mt-5">
              <div className="head">
                <h2>DCOC</h2>
              </div>
              <div className="head">
                <span>
                  DCOC: Diploma emphasizing cybersecurity and cryptography
                  skills for protecting networks and data.
                </span>
              </div>
            </div>

            <div className="col-sm-2 core py-5 mx-3 border mt-5">
              <div className="head">
                <h2>English Spoken</h2>
              </div>
              <div className="head">
                <span>
                  English Spoken: A course designed to improve spoken English
                  skills, focusing on pronunciation, fluency, and communication
                  in various contexts.
                </span>
              </div>
            </div>

            <div className="col-sm-2 core py-5 mx-3 border  mt-5">
              <div className="head">
                <h2>EXCEL</h2>
              </div>
              <div className="head">
                <span>
                  Excel: Software for creating, organizing, and analyzing data
                  using spreadsheets, widely used in business, finance, and
                  academia for its versatility and functionality.
                </span>
              </div>
            </div>

            <div className="col-sm-2 core py-5 mx-3 border  mt-5">
              <div className="head">
                <h2>MCA</h2>
              </div>
              <div className="head">
                <span>
                  MCA (Master of Computer Applications): Advanced program
                  blending computer science, software development, and IT
                  management for diverse career paths.
                </span>
              </div>
            </div>
          </div>
          {showMore && (
            <>
              <div className="row justify-content-center mx-3 mb-5">
                <div className="col-sm-2 core py-5 mx-3 border  mt-5">
                  <div className="head">
                    <h2>PGDCA</h2>
                  </div>
                  <div className="head">
                    <span>
                      PGDCA: Intensive diploma focusing on advanced computer
                      applications and programming for IT careers.Previous
                    </span>
                  </div>
                </div>

                <div className="col-sm-2 core py-5 mx-3 border  mt-5">
                  <div className="head">
                    <h2>RSCFA</h2>
                  </div>
                  <div className="head">
                    <span>
                      It's a practical course in financial accounting offered in
                      Rajasthan, India.
                    </span>
                  </div>
                </div>

                <div className="col-sm-2 core py-5 mx-3 border  mt-5">
                  <div className="head">
                    <h2>RS-CIT</h2>
                  </div>
                  <div className="head">
                    <span>
                      RS-CIT: Government-recognized course promoting basic
                      computer literacy and IT skills in Rajasthan, India.
                    </span>
                  </div>
                </div>

                <div className="col-sm-2 core py-5 mx-3 border  mt-5">
                  <div className="head">
                    <h2>Advanced Excel</h2>
                  </div>
                  <div className="head">
                    <span>
                      Advanced Excel includes features for complex calculations,
                      data analysis, automation, and visualization, such as
                      PivotTables, macros, advanced formulas, data validation,
                      and charts/graphs.
                    </span>
                  </div>
                </div>
              </div>

              <div className="row justify-content-center  mx-3 mb-5">
                <div className="col-sm-2 core py-5 mx-3 border  mt-5">
                  <div className="head">
                    <h2>Buzy Accounting</h2>
                  </div>
                  <div className="head">
                    <span>
                      Buzy Accounting Software is a user-friendly tool for small
                      to medium-sized businesses, offering features for managing
                      finances, inventory, billing, GST compliance, and
                      reporting.
                    </span>
                  </div>
                </div>

                <div className="col-sm-2 core py-5 mx-3 border mt-5">
                  <div className="head">
                    <h2>Computer Basics</h2>
                  </div>
                  <div className="head">
                    <span>
                      Computer basics cover hardware (like CPU, RAM, and hard
                      drive), software (including operating systems and
                      applications),file management, networking, security,
                      troubleshooting, and backup/recovery.
                    </span>
                  </div>
                </div>
              </div>
            </>
          )}
          <div className="text-center">
            <label
              htmlFor=""
              className="fs-4 mb-4"
              style={{
                cursor: "pointer",
                color: "white",
                textDecoration: "underline ",
              }}
              onClick={toggleShowMore}
            >
              {showMore ? "Less" : "More"}
            </label>
          </div>
        </div>
      </div>

      {/* section7 */}
      <div id="gal">
        <div className="container">
          <div className="row mt-5 py-5 ">
            <div className="col-12">
              <h7 className="fs-1">Gallery</h7>
            </div>
          </div>
          <div className="row mt-5 mb-5">
            <div className="col-sm-12 h-50 col-md-12 col-lg-12 col-12">
              <div className="row text-center">
                <div
                  id="carouselExampleControls"
                  className="carousel slide"
                  data-bs-ride="carousel"
                >
                  <div className="carousel-inner ">
                    <div className="carousel-item active ">
                      <img
                        src={gallery1}
                        className="d-none d-sm-none d-md-block d-lg-block d-xl-block d-xxl-block"
                        alt="..."
                      />
                      <img
                        src={gallery4}
                        style={{ height: "500px" }}
                        className="d-block d-sm-none d-md-none d-lg-none d-xl-none d-xxl-none m-auto"
                        alt=""
                      />
                    </div>
                    <div className="carousel-item">
                      <img
                        src={gallery2}
                        className="d-none d-sm-none d-md-block d-lg-block d-xl-block d-xxl-block"
                        alt="..."
                      />
                      <img
                        src={gallery6}
                        style={{ height: "500px" }}
                        className="d-block  d-sm-none d-md-none d-lg-none d-xl-none d-xxl-none m-auto"
                        alt=""
                      />
                    </div>
                    <div className="carousel-item">
                      <img
                        src={gallery3}
                        className="d-none d-sm-none d-md-block d-lg-block d-xl-block d-xxl-block"
                        alt="..."
                      />
                      <img
                        src={gallery5}
                        style={{ height: "500px" }}
                        className="d-block d-sm-none d-md-none d-lg-none d-xl-none d-xxl-none m-auto"
                        alt=""
                      />
                    </div>
                  </div>
                  <button
                    className="carousel-control-prev"
                    type="button"
                    data-bs-target="#carouselExampleControls"
                    data-bs-slide="prev"
                  >
                    <span
                      className="carousel-control-prev-icon"
                      aria-hidden="true"
                    ></span>
                    <span className="visually-hidden">Previous</span>
                  </button>
                  <button
                    className="carousel-control-next"
                    type="button"
                    data-bs-target="#carouselExampleControls"
                    data-bs-slide="next"
                  >
                    <span
                      className="carousel-control-next-icon"
                      aria-hidden="true"
                    ></span>
                    <span className="visually-hidden">Next</span>
                  </button>
                </div>
              </div>
              <div>
                <label
                  className="fs-3 offset-9 more mt-5"
                  onClick={() => {
                    navigate("/Gallerysection");
                  }}
                >
                  More...
                </label>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* section8 */}
      <div className="container-fluid">
        <div id="con">
          <div className="row">
            <div className="col-sm-12  ">
              <div className="text-center   mb-5 ">
                <h1 className="fs-1 fw-bold">Lets Connect</h1>
              </div>
            </div>
          </div>
          <form onSubmit={submitdata}>
            <div className="row">
              <div className="col-sm-12  mb-5 b ">
                <div className="mb-3 ">
                  <label htmlFor="" className="fs-3 mx-5  ms-0 px-5  ">
                    Name
                  </label>
                  <input
                    type="text"
                    className="lin"
                    placeholder="John"
                    onChange={(e) => {
                      setfname(e.target.value);
                    }}
                    value={fname}
                  />
                </div>

                <div className="mb-3">
                  <label htmlFor="" className="fs-3 mx-5  ">
                    Contact No
                  </label>
                  <input
                    type="text"
                    className="lin"
                    name=""
                    id=""
                    placeholder="+91 123456789"
                    onChange={(e) => {
                      setNumber(e.target.value);
                    }}
                    value={number}
                  />
                </div>

                <div className="mb-3">
                  <label htmlFor="" className="fs-3 mx-5 px-2 ">
                    Email ID
                  </label>
                  <input
                    type="text"
                    name=""
                    id=""
                    className="ms-4 lin"
                    placeholder="eg.12@gmail.com"
                    onChange={(e) => {
                      setEmail(e.target.value);
                    }}
                    value={email}
                  />
                </div>

                <div className="mb-5 ">
                  <label htmlFor="" className="fs-3 mx-5 px-2">
                    Discription
                  </label>
                  {/* <textarea name="" id="" cols="30" rows="3" className=''></textarea> */}
                  <input
                    type="text"
                    className="lin"
                    name=""
                    id=""
                    placeholder="Some Discription"
                    onChange={(e) => {
                      setdiscription(e.target.value);
                    }}
                    value={discription}
                  />
                </div>
                <div className=" text-center">
                  <button
                    className=" bit w-20 fs-5 h-50 px-5 py-2"
                    type="submit"
                  >
                    Connect
                  </button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>

      {/* section9 */}
      <footer className="foot container-fluid  ">
        <div className="">
          <div className="row">
            <div className="col-sm-6   mt-3 py-3 ">
              <div className=" text-center mx-5 text-sm-start">
                <img className="" src={logo} alt="" />
              </div>
            </div>
            <div className="col-sm-6 text-center mt-4  py-3">
              <div className="row">
                <div className="col-sm-3">
                  <Nav.Link href="#cou" className="me-5 fs-4">
                    Courses
                  </Nav.Link>
                </div>
                <div className="col-sm-3">
                  <Nav.Link href="#abou" className="me-5 fs-4">
                    About Us
                  </Nav.Link>
                </div>
                <div className="col-sm-3">
                  <Nav.Link
                    href="#"
                    className="me-5 fs-4"
                    onClick={() => {
                      navigate("/Gallerysection");
                    }}
                  >
                    Gallery
                  </Nav.Link>
                </div>
                <div className="col-sm-3">
                  <Nav.Link href="#con" className="me-5 fs-4">
                    Contact Us
                  </Nav.Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>

      <div>
        <div className="row w-100 text-center mt-4">
          <div className="fs-6 mb-4">
            &copy;Designed And Developed By{" "}
            <a
              href="https://www.unairesync.com/"
              className="link"
              target="blank"
            >
              Unaire Sync
            </a>
          </div>
        </div>
      </div>
    </>
  );
};

export default Nvep;
